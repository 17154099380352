import React from "react";

import { TcTitle, TcSubtitle } from "../../pages/terms-conditions";

const TermsConditionsEnglish = () => {
  return (
      <>
        <TcTitle>Terms & Conditions of “KLM Making Memories”</TcTitle>
        <p>
          <i>No purchase necessary.</i>
        </p>
        <TcSubtitle>1. Promotion</TcSubtitle>
        <ul>
          <li>
            These Terms and Conditions apply to the KLM Making Memories promotion
            (hereinafter: ‘Promotion’) that is only available at the Making Memories website
            and is organised by Koninklijke Luchtvaart Maatschappij N.V. (also known as KLM Royal Dutch Airlines),
            Amsterdamseweg 55, 1182 GP Amstelveen, The Netherlands (“KLM”).
            Participation in this Promotion means acceptance of these terms and conditions.
          </li>
          <li>
            Participation in the Promotion and the chance to win the Prize is done by
            completing the game “KLM Making Memories”. It is not necessary to have all the
            answers correct.
          </li>
          <li>
            The Participant in this Promotion will play a memory game and select all the
            pictures that belong to a certain destination. Each participant will be eligible to
            have a chance of winning the prize – return flight ticket.
          </li>
        </ul>

        <TcSubtitle>2. Participation</TcSubtitle>
        <ul>
          <li>
            Participation in this Promotion is voluntary and limited to one entry per person.
          </li>
          <li>
            Participation in the Promotion is free of charge and a Participant needs a valid
            name, last name and email address.
          </li>
          <li>
            A Participant can participate once per unique name, last name, country and email
            address.
          </li>
          <li>
            For the purposes of this Promotion, the linked email address will be considered
            the participation number.
          </li>
          <li>
            The Promotion is not open to employees of AIR FRANCE and KLM or to employees
            of companies involved in the organisation of the Promotion.
          </li>
          <li>
            KLM reserves the right to require written proof of a Winner’s residence and age
            (above 18 years) before awarding a prize.
          </li>
          <li>
            KLM reserves the right to exclude Participants. Incorrect or incomplete
            submissions shall not be accepted.
          </li>
          <li>
            Using details of third parties is strictly forbidden. Should any such use take place,
            KLM reserves the right to disqualify the Participant a definitively. KLM reserves the
            right to remove submissions from participation.
          </li>
        </ul>

        <TcSubtitle>3. Duration</TcSubtitle>
        <ul>
          <li>
            This Promotion begins on November 15th 2022 at 00.01 am and final entry is at
            23.59pm on November 22th 2022 (Amsterdam time zone).
          </li>
        </ul>

        <TcSubtitle>4. Prize</TcSubtitle>
        <p>The prize consists of:</p>
        <ul>
          <li>
            1 x 2 KLM Economy Class return trip tickets to a destination of choice of the
            winner within the KLM network to a selected KLM destination and via Amsterdam
            with an average economic value of max €1000 per ticket (the <b>“Prize”</b>).
          </li>
          <li>
            The winner of the Prize must start and end his or her journey in the Netherlands,
            at Schiphol. The winner must arrange transport to and from Schiphol himself, and
            at his or her own expense.
          </li>
        </ul>

        <p>
          <b>Information in respect of the Prize and how it will be awarded</b>
        </p>

        <ul>
          <li>
            The winner will be chosen at random by a notary, means of a draw among all
            customers who have participated.
          </li>
          <li>
            The winner of the Prize must book his/her flight and commence his/her journey
            within one year after the Prize has been awarded.
          </li>
          <li>
            The winner will receive an e-mail stating that he/she has won and how he/she can
            accept the prize on Thursday 1st of December 2022.
          </li>
          <li>
            All decisions made by KLM regarding this Prize shall be considered final; no
            correspondence will be entered into.
          </li>
          <li>The tickets are non-refundable and non-flexible.</li>
          <li>
            Tickets are subject to availability; there is very limited availability during busy
            periods (for example school holidays or important events).Tickets are awarded on
            a strictly personal basis and are non-transferable. Similarly, tickets may not be
            exchanged for money or any other form of remuneration. The winner can indicate
            one other person who will travel with him/her. Once the name of this person has
            been communicated to KLM, the tickets will be issued on a strictly personal basis
            and may not be transferred. Both persons must travel together on the outward
            and return journey.
          </li>
          <li>
            The winner may not use the second ticket himself.
          </li>
          <li>
            KLM is responsible for any tax on games of chance. The winner of the tickets shall
            be responsible for any charges, (credit card) costs, requests for any visa
            application costs or gratuities not explicitly included in or associated with this prize
            (such as extra meals, transfers to and from Amsterdam Schiphol Airport, parking
            costs at Amsterdam Schiphol Airport, travel insurance).
          </li>
          <li>
            In accepting the Prize, the winner acknowledges that he/she does so entirely at
            his/her own risk and responsibility.
          </li>
          <li>
            The Prize may not be exchanged for any other prizes or services or for cash. This
            prize is non-transferable.
          </li>
          <li>
            KLM reserves the right to change the type of Prize in the course of the Promotion
            or to replace the Prize with a different prize of equal value. Under no
            circumstances shall this alternative lead to replacement or refund.
          </li>
        </ul>

        <TcSubtitle>5. Personal details</TcSubtitle>
        <ul>
          <li>
            Participant will receive a maximum of 2 e-mails related to this Promotion.
          </li>
          <li>
            KLM will make sure that the collection and processing of this information is in
            accordance with Dutch privacy laws.
          </li>
          <li>
            This Promotion is subject to the KLM Privacy policy, which is available <a href="https://www.klm.nl/en/information/legal/privacy-policy">here</a>.
          </li>
          <li>
            KLM and / or its subcontractors will not use the personal data of the participants
            for other commercial purposes or for sale to a third party.
          </li>
        </ul>

        <TcSubtitle>6. Questions and complaints</TcSubtitle>
        <ul>
          <li>
            Users who have any questions or complaints regarding the Promotion can address
            their complaints via our KLM social media 24/7.
          </li>
        </ul>

        <TcSubtitle>7. Miscellaneous</TcSubtitle>
        <ul>
          <li>
            If, for any reason, the Promotion cannot be run as planned, or infection by
            computer viruses or bugs, tampering, unauthorised intervention, fraud, actions by
            Participants, technical failures, or any other causes that corrupt or compromise
            the administration, security, fairness, integrity, or proper conduct of the
            Promotion, KLM reserves the right at its sole discretion to disqualify any entry or
            entrant and/or to cancel, terminate, modify, or suspend the Promotion, and, at its
            sole discretion, to award a Prize from among all eligible non-suspect entries
            validly received prior to the action requiring such modification. KLM is not
            responsible for network, computer hardware or software jamming or outages of
            any nature that restrict, delay or cause the loss of Participant forms or other data.
          </li>
          <li>
            KLM reserves the right to change the Terms and Conditions, Prizes, definitions or
            other information, or to cancel the Promotion at any time without prior notice.
          </li>
          <li>
            The liability of KLM is limited exclusively to the value of the chosen Prize. By
            registering, Participants release and agree to defend, indemnify and hold KLM
            harmless from and against any and all claims, demands, losses and causes of
            action for any damages (including, without limitation, all consequential, economic
            and incidental damages), liability, loss, costs, fees (including attorney fees),
            including, without limitation, damage to a persons identity, reputation, likeness or
            that of any third party (including death) or personal or real property, due in whole
            or in part, directly or indirectly, by reason of the acceptance, possession or
            participation in the Promotion and whether or not caused, in whole or in part, by
            the acts, omissions, negligence or misconduct of KLM.
          </li>
          <li>
            This Promotion is subject to the code of conduct for promotional games of chance.
            (Wet op de Promotionele Kansspelen).
          </li>
          <li>
            This Promotion (including these Terms & Conditions) is subject to Dutch law. In
            case of disputes, the Court in Amsterdam has exclusive authority to rule on these
            disputes unless mandatory law provides otherwise.
          </li>
        </ul>

        <TcSubtitle id="privacy">8. Privacy Policy</TcSubtitle>
        <ul>
          <li>
            We will send the Participant the KLM newsletter weekly, which keeps the
            Participant informed on attractive fares, inspiring destinations, package deals and
            the latest news. We may also send the Participant emails on specific occasions,
            such as a special offer on your birthday, personalized offers for your next trip or to
            assist you with your booking when you leave the procedure before completion.
          </li>
          <li>
            In our newsletters we may include offers for package deals which are offered by
            our partner Airtrade. All newsletters are sent by KLM, no contact details are shared
            with our partners for this purpose. If the Participant is a Flying Blue member, we
            may include the Flying Blue number and special offers in relation to Flying Blue in
            our newsletters. We offer Flying Blue together with our group company Air France.
            For more information, please check paragraph ‘Who we are’ under our <a href="https://www.klm.nl/en/information/legal/privacy-policy">privacy
            policy</a>.
          </li>
          <li>
            To understand what is relevant for the Participant, we use automatic tools to
            analyze personal data. The Participant has the right to object to such use any time.
            For more information on how we use your data and your rights, please check our
            {" "}<a href="https://www.klm.nl/en/information/legal/privacy-policy">privacy policy</a>.
          </li>
          <li>
            If a Participant no longer wishes to receive the KLM Newsletter, then the
            Participant can unsubscribe via the button that can be found at the bottom of the
            KLM Newsletter.
          </li>
        </ul>
      </>
  );
};

export default TermsConditionsEnglish;
