import React from "react";

import { TcTitle, TcSubtitle } from "../../pages/terms-conditions";

const TermsConditionsDutch = () => {
  return (
      <>
        <TcTitle>Algemene voorwaarden van KLM Making Memories</TcTitle>
        <p>
          <i>Het is niet nodig om een aankoop te doen.</i>
        </p>
        <TcSubtitle>
          I. Informatie over de actie en de algemene regels:
        </TcSubtitle>
        <p>
          Deze algemene voorwaarden (“Algemene Voorwaarden”) zijn van toepassing op KLM’s Actie “KLM
          Making Memories” ( de “Actie”) die wordt georganiseerd door de Koninklijke Luchtvaart Maatschappij
          N.V., Amsterdamseweg 55, 1182 GP Amstelveen, Nederland (“KLM”). De Actie start op 15 november 2022
          om 00.01 en de laatste inzending wordt geaccepteerd om uiterlijk 23.59 uur op 22 november 2022 (GMT +1).
        </p>
        <p>
          De Actie is alleen toegankelijk via de Making Memories website.
        </p>
        <TcSubtitle>II. Deelname aan de Actie</TcSubtitle>
        <ul>
          <li>
            Een “Deelnemer” is als volgt gedefinieerd: een persoon, minimaal 18 jaar of ouder op het moment van
            deelname, met een vaste woon– of verblijfplaats in Nederland.
          </li>

          <li>Deelname aan deze Actie is vrijwillig en kosteloos.</li>

          <li>
            Deelname aan deze Actie betekent acceptatie van deze Algemene Voorwaarden.
          </li>

          <li>
            In het kader van deze Actie wordt het gekoppelde e-mailadres beschouwd als het
            deelnemingsnummer.
          </li>

          <li>
            Om kans te maken op de Prijs dient de Deelnemer het spel ‘KLM Making Memories’ te voltooien. Het
            is niet nodig om alle antwoorden correct te hebben.
          </li>

          <li>
            De Actie bestaat uit een memory spel, waarbij de Deelnemer de juiste afbeeldingen moet selecteren
            bij een bepaalde bestemming.
          </li>

          <li>
            Werknemers van KLM en haar onderaannemers, alsmede hun 1e graads familieleden (kinderen), zijn
            uitgesloten van deelname aan de Actie.
          </li>
          <li>
            Het gebruik van persoonsgegevens van derden door de Deelnemer is ten strengste verboden.
            Mocht dergelijk misbruik plaatsvinden dan behoudt KLM het recht om de Deelnemer definitief uit te
            sluiten van deelname.
          </li>
          <li>
            Iedere Deelnemer mag slechts één keer deelnemen. Personen die met meerdere Deelnemers aan de
            Actie op één en hetzelfde huisadres wonen, mogen allen eenmaal meedoen aan de Actie.
          </li>
          <li>
            Een Deelnemer mag slechts éénmaal meespelen met dezelfde voornaam, achternaam en e-mailadres.
          </li>
          <li>
            Alleen een Deelnemer die gedurende de Actieperiode aan alle Actievoorwaarden voldoet, maakt kans
            op een Prijs.
          </li>
          <li>
            Onjuiste of onvolledige inzendingen worden niet aanvaard.
          </li>
        </ul>
        <TcSubtitle>III. Informatie over de prijs</TcSubtitle>
        <ul>
          <li>
            Deelnemers die aan de Actie deelnemen, maken na voltooien van de Actie kans op het winnen van een
            prijs (de “Prijs”). De Prijs betreft 1x 2 KLM Economy Class retour vliegtickets ter waarde van max EUR
            1000 (zegge: duizend euro) per ticket naar een (intercontinentale) KLM-bestemming naar keuze binnen
            het KLM-netwerk.
          </li>
        </ul>
        <p>
          <b>
            Informatie met betrekking tot de Prijs en de wijze van uitreiking:
          </b>
        </p>
        <ul>
          <li>Na de Actieperiode wordt de Prijs weggegeven aan 1 winnaar.</li>
          <li>
            De winnaar van de Prijs wordt willekeurig geselecteerd door een notaris uit de groep Deelnemers die
            aan alle Algemene Voorwaarden voldoen. Op donderdag 1 december 2022 maken we de winnaar
            bekend via e-mail.
          </li>
          <li>
            Een winnaar dient zijn of haar reis in Nederland, op Schiphol, aan te vangen en te eindigen. Een
            winnaar dient zelf, en op eigen kosten, zorg te dragen voor vervoer naar Schiphol.
          </li>
          <li>
            De winnaar is zelf verantwoordelijk voor eventuele belastingen (exclusief kansspelbelasting), kosten en
            gratificaties die niet expliciet zijn opgenomen in of vergezeld gaan van deze prijs (zoals verblijf, vervoer
            naar luchthaven Schiphol v.v., parkeerkosten op Schiphol, fooien, reisverzekering).
          </li>
          <li>
            De winnaar stemt er bij het aanvaarden van een Prijs in toe dat aanvaarding geheel voor zijn/haar
            eigen risico en verantwoording is.
          </li>
          <li>
            De gewonnen KLM Economy-tickets zijn niet inwisselbaar voor geld of goederen en zijn niet
            overdraagbaar aan derden.
          </li>
          <li>
            Van de twee gewonnen KLM Economy-tickets wordt één ticket op naam van de winnaar gesteld en
            één ticket op naam van een introducé(e) van de winnaar. De winnaar en zijn of haar introducé(e) dienen
            op de heen- én de terugreis samen te reizen.
          </li>
          <li>
            Een winnaar mag niet zelf gebruik maken van het tweede ticket.
          </li>
          <li>
            Nadat de naam van de introducé(e) aan KLM is meegedeeld, worden de tickets op strikt persoonlijke
            basis verstrekt.
          </li>
          <li>
            De namen van de personen en de geboekte reisdata kunnen niet worden gewijzigd.
          </li>
          <li>
            De gewonnen KLM Economy-tickets zijn onderhevig aan bepaalde, beperkende voorwaarden. Deze
            voorwaarden worden aan de winnaar van de Prijs gecommuniceerd.
          </li>
          <li>
            Tickets zijn op basis van beschikbaarheid; gedurende drukke periodes (zoals schoolvakanties of
            belangrijke evenementen) is de beschikbaarheid beperkt.
          </li>
          <li>
            De winnaar krijgt een e-mailbericht van KLM met verdere informatie over hoe de gewonnen Prijs in
            ontvangst genomen kan worden. Dit e-mailbericht is niet het daadwerkelijke vliegticket. Het vliegticket
            ontvangt de Deelnemer na verzilveren van de Prijs bij KLM.
          </li>
          <li>
            De winnaar krijgt persoonlijk bericht hoe men zijn of haar Prijs kan verzilveren en de twee KLM
            Economy-tickets kan boeken bij KLM.
          </li>
          <li>
            De winnaar van de Prijs moet zijn/haar ticket boeken en zijn/haar reis moet aanvangen binnen één
            jaar nadat de Prijs is uitgereikt.
          </li>
          <li>
            KLM is gerechtigd schriftelijke verificatie van de woonplaats en de leeftijd van de eventuele winnaar te
            eisen voordat de Prijs wordt toegekend. KLM is gerechtigd inzendingen die niet voldoen aan deze
            Algemene Voorwaarden uit te sluiten van deelname en zodoende de Prijs niet toe te kennen.
          </li>
          <li>
            Alle beslissingen die door KLM worden genomen met betrekking tot deze Actie zijn definitief en
            hierover kan niet worden gecorrespondeerd.
          </li>
        </ul>
      <TcSubtitle>IV. Persoonsgegevens</TcSubtitle>
      <ul>
        <li>
          De deelnemer ontvangt maximaal 2 e-mails van KLM met betrekking tot deze Actie en de uitkomst van
          de trekking.
        </li>
        <li>
          KLM en/of haar onderaannemers zullen de persoonsgegevens niet gebruiken voor andere
          commerciële doeleinden of verkopen aan een derde partij. KLM’ s Privacybeleid is <a href="https://www.klm.nl/information/legal/privacy-policy">hier</a> te vinden en is
          van toepassing op de bewerking van persoonsgegevens.
        </li>
      </ul>
      <TcSubtitle>V. Vragen</TcSubtitle>
      <ul>
        <li>
          Vragen, opmerkingen of eventuele klachten over deze Actie kunnen 24/7 worden geadresseerd via
          KLM social media.
        </li>
      </ul>
      <TcSubtitle>VI. Slotbepalingen</TcSubtitle>
      <ul>
        <li>
          Indien de Actie niet volgens plan kan verlopen om welke reden dan ook, of in het geval van besmetting
          door computervirussen of bugs, manipulatie, ongeoorloofde interventie, fraude, handelingen van
          Deelnemers, technische storingen of andere oorzaken die de administratie, veiligheid, rechtvaardigheid,
          integriteit of het goed gedrag in het kader van de Actie compromitteren of in gevaar brengen, dan
          behoudt KLM zich het recht voor om naar eigen goeddunken een inzending of deelnemer te
          diskwalificeren en/of de Actie te annuleren, te beëindigen, te wijzigen of op te schorten, en naar eigen
          goeddunken een Prijs toe te kennen aan een willekeurige in aanmerking komende, niet-verdachte
          inzending die voorafgaand aan de handeling die tot deze aanpassing heeft geleid op geldige wijze is
          ontvangen.
        </li>
        <li>
          KLM is niet verantwoordelijk voor storingen in het netwerken, de computer hardware of - software of
          storingen van welke aard dan ook die de ontvangst van Deelnemersformulieren of andere gegevens
          beperken, vertragen verlies daarvan veroorzaken.

        </li>
        <li>
          KLM behoudt zich het recht voor de Algemene Voorwaarden, de Prijzen, de definities of andere
          informatie aan te passen of de Actie te annuleren, zulks op enigerlei moment tijdens de Actie en zonder
          daarbij enige vorm van aankondiging in acht te hoeven nemen. In geen geval zal dit alternatief leiden tot
          vervanging of terugbetaling.
        </li>
        <li>
          De aansprakelijkheid van KLM is enkel beperkt tot de waarde van de gekozen Prijs.
        </li>
        <li>
          Door zich aan te melden, zien Deelnemers af van en gaan zij ermee akkoord KLM te vrijwaren van en
          beschermen tegen alle claims, eisen, verliezen en rechtsvorderingen voor alle schade (met inbegrip van,
          maar niet beperkt tot alle gevolgschade, economische en incidentele schade), aansprakelijkheid, verlies,
          kosten, honoraria (inclusief van advocaten), met inbegrip van, maar niet beperkt tot schade aan de
          identiteit, reputatie of gelijkenis (inclusief overlijden) van personen of derden, of persoonlijke of
          onroerende goederen, geheel of gedeeltelijk, als direct of indirect gevolg van aanvaarding of bezit van of
          deelname aan de Actie, en al dan niet geheel of gedeeltelijk veroorzaakt door het handelen, nalaten,
          nalatigheid of wangedrag van KLM.
        </li>
        <li>
          De Nederlandse Gedragscode voor Promotionele Kansspelen is van toepassing op deze Actie.
        </li>
        <li>
          Op deze Actie waaronder ook deze Algemene Voorwaarden is het Nederlands recht van toepassing.
        </li>
        <li>
          De rechter van de rechtbank Amsterdam is bij uitsluiting bevoegd van eventuele geschillen kennis te
          nemen, tenzij de wet dwingend anders voorschrijft.
        </li>
      </ul>

      <TcTitle id="privacy">Privacybeleid</TcTitle>
      <ul>
        <li>
          De Deelnemer ontvangt elke week de KLM-nieuwsbrief, waarmee we de Deelnemer op de hoogte
          houden van aantrekkelijke tarieven, inspirerende bestemmingen, pakketreizen en het laatste nieuws.
          Daarnaast ontvangt de Deelnemer incidentele e-mails, zoals een speciale verjaardag aanbieding,
          gepersonaliseerde aanbiedingen voor de Deelnemer zijn volgende reis of als hulp wanneer de
          boekingsprocedure verlaat wordt voordat deze is voltooid.
        </li>
        <li>
          Alle nieuwsbrieven worden door KLM verzonden en contactgegevens worden niet gedeeld met onze
          partners. Als de Deelnemer een Flying Blue-deelnemer is, dan kunnen we de Deelnemer zijn/haar Flying
          Blue-nummer en speciale aanbiedingen met betrekking tot Flying Blue in onze nieuwsbrieven opnemen.
          Wij bieden Flying Blue aan in samenwerking met ons groepsmaatschappij Air France. Raadpleeg voor
          meer informatie de paragraaf 'Wie we zijn' onder ons <a href="https://www.klm.nl/information/legal/privacy-policy">privacybeleid</a>.
        </li>
        <li>
          Om te begrijpen wat voor de Deelnemer relevant is, gebruiken wij automatische hulpmiddelen om
          persoonlijke gegevens te analyseren. De Deelnemer heeft te allen tijde het recht om hiertegen bezwaar
          te maken. Raadpleeg ons <a href="https://www.klm.nl/information/legal/privacy-policy">Privacybeleid</a> voor meer informatie over hoe wij omgaan met klantgegevens
          en uw rechten.
        </li>
        <li>
          Als de Deelnemer de KLM-nieuwsbrief in de toekomst niet langer wenst te ontvangen, kan de
          Deelnemer zich uitschrijven middels de afmeldlink onderaan de KLM-nieuwsbrief.
        </li>
      </ul>
    </>
  );
};

export default TermsConditionsDutch;
